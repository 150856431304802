<template>
  <b-card>
    <b-overlay
      :show="isLoading"
      opacity="0.70"
      variant="transparent"
      rounded="sm"
    >
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="2"
          xl="1"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="require('@/assets/images/logo/logo.png')"
              class="bg-avatar bg-color-avatar"
              size="80px"
              rounded
            />
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col lg="5">
          <h2 class="mb-2">Dados da empresa</h2>
          <table class="mt-2 mt-xl-0 w-98">
            <tr>
              <th class="pb-50">
                <feather-icon icon="CreditCardIcon" class="mr-75" />
                <span class="font-weight-bold">CNPJ:</span>
              </th>
              <td class="pb-50 text-capitalize font-weight-bold">
                {{ contractDetails.company ? contractDetails.company.cnpj : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="HomeIcon" class="mr-75" />
                <span class="font-weight-bold">Empresa:</span>
              </th>
              <td class="pb-50 text-capitalize font-weight-bold">
                {{ contractDetails.company ? contractDetails.company.company_name : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="HomeIcon" class="mr-75" />
                <span class="font-weight-bold">Nome Fantasia:</span>
              </th>
              <td class="pb-50 text-capitalize font-weight-bold">
                {{ contractDetails.company ? contractDetails.company.fantasy_name : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="GlobeIcon" class="mr-75" />
                <span class="font-weight-bold">Google Classroom:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ contractDetails.company ? contractDetails.company.classroom_domain : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="ListIcon" class="mr-75" />
                <span class="font-weight-bold">Categoria:</span>
              </th>
              <td class="pb-50" v-if="contractDetails.company && contractDetails.company.category == 'publica'">Empresa pública</td>
              <td class="pb-50" v-else>Empresa privada</td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">Telefone:</span>
              </th>
              <td class="pb-50">
                {{ contractDetails.company ? contractDetails.company.phone : '' }}
              </td>
            </tr>
          </table>
        </b-col>
        <!-- Endereço -->
        <b-col>
          <h2 class="mb-2">Dados da assinaturas</h2>
          <table class="mt-2 mt-xl-0 w-98">
            <tr>
              <th class="pb-50">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">Data de cadastro:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ contractDetails.created_at | dateFormat }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">Data da expiração:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ contractDetails.expiration_date | dateFormat }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">Número de licenças:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ contractDetails.number_of_licences }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <hr />
      <div>
        <b-row>
          <b-col>
            <h3 class="mt-2">Lista de livros da assinaturas</h3>
          </b-col>
        </b-row>
        <!--<b-row>
          <b-col md="7">
            <b-row>
              <b-col md="4">
                <b-form-group
                  label="Exibir"
                  label-for="h-exibir"
                  label-cols-md="4"
                  class="text-muted"
                >
                  <b-form-select
                    size="sm"
                    class="form-content"
                    v-model="perPage"
                    :options="perPageOptions"
                    :disabled="isLoading"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="5">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                placeholder="Pesquisar"
                v-model="search"
                debounce="800"
                type="search"
                size="sm"
                :disabled="isLoading"
              />
            </b-input-group>
          </b-col>
        </b-row>-->
        <b-table-simple
          striped
          stacked="md"
          class="mt-2 responsive text-center"
          id="tableBook"
        >
          <b-thead>
            <b-tr class="text-center">
              <b-th>ISBN</b-th>
              <b-th>Nome do livro</b-th>
              <b-th>Tipo</b-th>
              <b-th>Autores</b-th>
              <b-th>Edição</b-th>
              <b-th>Quantidade</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr :key="indextr" v-for="(tr, indextr) in contractDetails.contract_items">
              <b-td>
                {{ tr.book ? tr.book.isbn : '' }}
              </b-td> 
              <b-td>
                {{ tr.book ? tr.book.name : '' }}
              </b-td> 
              <b-td>
                {{ tr.book ? tr.book.type : '' }}
              </b-td>
              <b-td>
                {{ tr.book ? tr.book.author : '' }}
              </b-td>
              <b-td>
                {{ tr.book ? tr.book.edition : '' }}
              </b-td>
              <b-td>
                {{ tr.amount }}
              </b-td>            
            </b-tr>
          </b-tbody>
        </b-table-simple>
<!--         <b-row class="mt-1">
          <b-col md="10">
            <b-row>
              <b-col md="5">
                Exibindo {{ perPage }} de {{ totalRows }} registros
              </b-col>
            </b-row>
          </b-col>
          <b-col md="2">
            <b-pagination
              @change="handlePageChange"
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalRows"
              aria-controls="tableBook"
              class="mt-1"
            />
          </b-col>
        </b-row> -->
      </div>
    </b-overlay>
  </b-card>
</template>
<script>
/* eslint-disable */
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
     vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      isLoading: true,
      isLoadingStore: false,

      showUpdate: false,
      contractDetails: {},

      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',

      perPageOptions: [
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 20, text: "20" },
      ],
    }
  },
  computed: {
    ...mapState("contracts", ["contracts"]),
  },
  created(){
    this.showContract()
     if (! this.csrf) {
      console.warn('The CSRF token is missing. Ensure that the HTML header includes the following: <meta name="csrf-token" content="{{ csrf_token() }}">');
    }
  },
  methods: {
    ...mapActions("contracts", ["fetchDetails"]),

    showContract(paginated = false, page = 1){
      this.isLoading = true
      this.$store.dispatch("contracts/fetchDetails", {
          contract_id: this.$route.params.contract_id,
          paginate: paginated,
          page: page,
          generic: this.search,
          per_page: this.perPage
        })
        .then((response) => {
          this.contractDetails = response
          this.totalRows = response.total
        }) 
        .finally(() => {
          this.isLoading = false
        })
    },

    handlePageChange(value) {
      //this.showContract(true, value);
    },
  },
  watch: {
    perPage(){
      //this.showBook()
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.bg-color-avatar {
  background: none !important;
}
</style>
